<template>
  <div class="scl-stage-box">
    <div
      class="mb-1"
    >
      <h4
        class="float-left scl-title-hover"
        :class="{ active: hover }"
        @click="showReview"
        @mouseover="hover = alreadyImported"
        @mouseleave="hover = false"
      >
        {{ title }}
      </h4>
      <feather-icon
        icon="ChevronRightIcon"
        class="float-right"
        size="24"
        :class="{ active: hover }"
        @click="showReview"
        @mouseover="hover = alreadyImported"
        @mouseleave="hover = false"
      />
      <div v-if="showNotification">
        <feather-icon
          :id="`${name}-status-button`"
          :icon="statusIcon"
          :class="`float-right ${statusCss}`"
          size="24"
        />
        <b-popover
          :target="`${name}-status-button`"
          triggers="hover"
          placement="topright"
          variant="light"
          class="scl-popover"
        >
          <template
            #title
          >
            <span :class="statusHeadingCss">{{ statusTitle }}</span>
          </template>
          <p
            v-for="(message, index) in statusMessage"
            :key="index"
          >
            <span v-if="message.row !== undefined">File row: {{ message.row }} - {{ message.message }}</span>
            <span v-else>{{ message }}</span>
          </p>
        </b-popover>
      </div>
      <b-form-group
        v-if="alreadyImported && ppeEnabled"
        label=""
        label-for="ppe-switch"
        class="float-right"
      >
        <b-form-checkbox
          v-model="ppeSwitch"
          name="ppe-switch"
          switch
          inline
          :disabled="ppeAlreadyImported"
        />
      </b-form-group>
    </div>
    <br>
    <!-- <div v-cloak @drop.prevent="addFile" @dragover.prevent>
      <h4 v-if="alreadyImported == false">
        Drag and drop file here to upload
      </h4>

    </div> -->
    <div
      v-if="ppeRequired"
    >
      <b-form
        v-if="importPPEFile == null && !ppeAlreadyImported"
        ref="ppeUploadForm"
        class="mb-1 d-inline-block w-100"
      >
        <b-form-file
          ref="ppeFileUpload"
          v-model="importPPEFile"
          :state="importPPEFile"
          accept=".csv"
          browse-text="Drag & drop or browse"
          class="scl-upload-button"
          size="sm"
          placeholder="No PPE file uploaded"
          button-variant="primary"
          plain
          @input="uploadPPEFile"
        />
      </b-form>
      <router-link
        v-else
        :to="{ path: `/reviewpayments?batchid=${paymentBatchId}` }"
      >
        {{ ppeFileName !== '' ? ppeFileName : 'Imported file' }}
      </router-link>
      <b-progress
        v-model="uploadPPEPercentage"
        class="mt-1 mb-1"
        max="100"
      />
    </div>

    <b-form
      v-if="importFile == null && !alreadyImported"
      ref="uploadForm"
      class="d-inline-block"
    >
      <b-form-file
        ref="fileUpload"
        v-model="importFile"
        :state="Boolean(importFile)"
        accept=".csv"
        browse-text="Upload"
        plain
        class="scl-upload-button"
        @input="uploadFile"
      />
    </b-form>
    <router-link
      v-else
      :to="{ path: `/reviewpayments?batchid=${paymentBatchId}` }"
    >
      {{ fileName !== '' ? fileName : 'Imported file' }}
    </router-link>
    <b-progress
      v-model="uploadPercentage"
      class="mt-1"
      max="100"
    />
  </div>
</template>

<script>

import {
  BProgress, BFormFile, BPopover, BFormGroup, BFormCheckbox, BForm,
} from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import swal from 'sweetalert2'
import jwt from '@/auth/jwt/sclUseJwt'
import { mapGetters } from 'vuex'

export default {

  name: 'PaymentsUploadStageBox',
  components: {
    BProgress,
    BFormFile,
    BPopover,
    BFormGroup,
    BFormCheckbox,
    BForm,
    FeatherIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    linkTitle: {
      type: String,
      default: null,
      required: false,
    },
    ppeTitle: {
      type: String,
      default: null,
      required: false,
    },
    ppeEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    batchId: {
      type: Number,
      default: null,
      required: false,
    },
    clientId: {
      type: Number,
      default: null,
      required: false,
    },
    name: {
      type: String,
      default: null,
      required: false,
    },
    batchStatus: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      importFile: null,
      importPPEFile: null,
      // alreadyImported: false,
      fileName: '',
      ppeFileName: '',
      paymentBatchId: '',
      uploadPercentage: 0,
      uploadPPEPercentage: 0,
      showNotification: false,
      ppeRequired: false,
      statusIcon: '',
      statusCss: 'text-primary',
      statusHeadingCss: '',
      statusMessage: [],
      statusTitle: '',
      hover: false,
      // file: [],
    }
  },
  computed: {
    ...mapGetters({ searchCriteria: 'app/getCurrentPaymentSearchCriteria' }),
    alreadyImported() {
      return this.batchId > -1 && this.batchStatus > 1
    },
    ppeAlreadyImported() {
      if (this.ppeFileName === null || this.ppeFileName === '') {
        return this.ppeTitle !== null && this.ppeTitle !== ''
      }

      return true
    },
    ppeSwitch: {
      get() {
        if (this.ppeTitle !== null && this.ppeTitle !== '') {
          this.setPPESwitchToTrue()
        }
        return this.ppeRequired
      },
      set(value) {
        this.ppeRequired = value
      },
    },
  },
  created() {
    this.setPopUpMessage('', '')
    this.showNotification = false
    this.fileName = this.linkTitle
    this.ppeFileName = this.ppeTitle
    this.paymentBatchId = this.batchId
    if (this.batchStatus === undefined) {
      this.batchStatus = -1
    }
    // this.alreadyImported = this.batchId > -1 && this.batchStatus > 1
    this.uploadPercentage = this.alreadyImported === true ? 100 : 0
  },
  methods: {
    setPPESwitchToTrue() {
      this.ppeRequired = true
    },
    clearFile() {
      this.importFile = null
      this.fileName = ''
      this.uploadPercentage = 0
      /* eslint-disable */
      // this.$refs['uploadForm'].reset()
      // this.$refs['fileUpload'].value = null
      /* eslint-enable */
    },
    clearPPEFile() {
      this.importPPEFile = null
      this.ppeFileName = ''
      this.uploadPPEPercentage = 0
      /* eslint-disable */
      // this.$refs['ppeUploadForm'].reset()
      // this.$refs['ppeFileUpload'].value = null
      /* eslint-enable */
    },
    showReview() {
      if (this.alreadyImported !== true) return

      this.$router.push(`/reviewpayments?batchid=${this.paymentBatchId}`)
    },
    setValuesAfterUpload(result) {
      const bId = Number(result.batchid)
      console.log(bId)
      this.paymentBatchId = bId
      this.fileName = result.filename
      this.showNotification = result.result === 'failed'
      this.$store.commit('app/updateClientWithBatchNo', {
        clientid: this.clientId,
        date: result.date,
        filename: result.filename,
        batchid: result.batchid,
        itemcount: result.payitems,
        status: result.status,
        week: result.week,
      })
    },

    processUploadSuccess(result) {
      this.setValuesAfterUpload(result)
      this.uploadPercentage = 100
      this.showMessage('success', 'Success!', 'File successfully uploaded')
      this.setPopUpMessage('success', result.result)
      this.setPPESwitchToTrue()
    },
    uploadFile() {
      if (this.alreadyImported === true) {
        this.showMessage('error', 'Error', 'A file is already uploaded, to upload again delete the batch via the Review and confirm step')
        return
      }

      const config = {
        onUploadProgress: function uploadprogress(progressEvent) {
          let progressUpdateVal = progressEvent.loaded * 100
          progressUpdateVal /= progressEvent.total
          this.uploadPercentage = Number(Math.round(progressUpdateVal))
        },
      }
      const id = this.clientId
      const wk = this.searchCriteria.selectedWeek
      const yr = this.searchCriteria.selectedYear
      const fn = this.importFile.name
      const data = new FormData()
      data.append('clientid', id)
      data.append('year', yr)
      data.append('week', wk)
      data.append('importtype', 'S') // Standard import
      data.append('filename', fn)
      data.append('file', this.importFile)
      jwt.axiosIns.post('/uploadfile/', data, config)
      // data.append('batch_id', '442')
      // jwt.axiosIns.post('/uploadppe/', data, config)
        .then(response => {
          if (response.status === 200) {
            let result = ''
            if (Array.isArray(response.data) === true) {
              /* eslint-disable */
              result = response.data[0]
              /* eslint-enable */
            } else {
              result = response.data
            }

            if (result.result === 'success') {
              this.processUploadSuccess(result)
              return
            }

            if (result.result === 'failed') {
              if (result.batchid !== undefined && result.batchid !== null) {
                this.setValuesAfterUpload(result)
              } else {
                this.clearFile()
              }
              this.setPopUpMessage(result.message, result.result)
              this.showMessage('error', 'Error', result.message)
            } else {
              this.clearFile()
              this.setPopUpMessage(result.message, result.result)
              this.showMessage('error', 'Error', result.message)
            }
          } else {
            this.clearFile()
            this.setPopUpMessage(response.data.message, response.data.result)
            this.showMessage('error', 'Error', response.data.message)
          }
        })
        .catch(e => {
          this.clearFile()
          this.setPopUpMessage(e, 'failed')
          this.showMessage('error', 'Error', e)
          console.error(e.response)
        })
    },
    setPPEValuesAfterUpload(result) {
      this.ppeFileName = result.filename
      this.showNotification = result.result !== 'success'
    },
    processPPEUploadSuccess(result) {
      this.setPPEValuesAfterUpload(result)
      this.uploadPPEPercentage = 100
      this.showMessage('success', 'Success!', 'File successfully uploaded')
      this.setPopUpMessage('success', result.result)
    },
    uploadPPEFile() {
      if (this.ppeAlreadyImported === true) {
        this.showMessage('error', 'Error', 'A PPE file is already uploaded, to upload again delete the batch via the Review and confirm step')
        return
      }

      const config = {
        onUploadProgress: function uploadprogress(progressEvent) {
          let progressUpdateVal = progressEvent.loaded * 100
          progressUpdateVal /= progressEvent.total
          this.uploadPPEPercentage = Number(Math.round(progressUpdateVal))
        },
      }
      const id = this.clientId
      const yr = this.searchCriteria.selectedYear
      const wk = this.searchCriteria.selectedWeek
      const fn = this.importPPEFile.name
      const data = new FormData()
      data.append('clientid', id)
      data.append('batch_id', this.paymentBatchId)
      data.append('year', yr)
      data.append('week', wk)
      data.append('importtype', 'S') // Standard import
      data.append('filename', fn)
      data.append('file', this.importPPEFile)
      jwt.axiosIns.post('/uploadppe/', data, config)
      // data.append('batch_id', '442')
      // jwt.axiosIns.post('/uploadppe/', data, config
        .then(response => {
          if (response.status === 200) {
            let result = ''
            if (Array.isArray(response.data) === true) {
              /* eslint-disable */
              result = response.data[0]
              /* eslint-enable */
            } else {
              result = response.data
            }

            if (result.result === 'success') {
              this.processPPEUploadSuccess(result)
              return
            }

            if (result.result === 'warning') {
              this.setPPEValuesAfterUpload(result)
              this.setPopUpMessage(result.message, result.result)
              this.showMessage('warning', 'Warning', result.message)
            } else if (result.result === 'failed') {
              this.clearPPEFile()
              this.setPopUpMessage(result.message, result.result)
              this.showMessage('error', 'Error', result.message)
            } else {
              this.clearPPEFile()
              this.setPopUpMessage(result.message, result.result)
              this.showMessage('error', 'Error', result.message)
            }
          } else {
            this.clearPPEFile()
            this.setPopUpMessage(response.data.message, response.data.result)
            this.showMessage('error', 'Error', response.data.messagee)
          }
        })
        .catch(e => {
          console.log(e.response)
          this.clearPPEFile()
          this.setPopUpMessage(e, 'failed')
          this.showMessage('error', 'Error', e)
          console.error(e.response)
        })
    },
    setPopUpMessage(message, result) {
      this.showNotification = result !== 'success'
      let css = 'text-danger'
      let title = 'Error'
      let headCss = 'scl-error-heading'
      if (result === 'warning') {
        css = 'text-warning'
        title = 'Warning'
        headCss = 'scl-warning-heading'
      }

      if (this.showNotification) {
        this.statusIcon = 'AlertCircleIcon'
        this.statusCss = css
        this.statusTitle = title
        this.statusHeadingCss = headCss
        if (Array.isArray(message)) {
          // let mess = ''
          // for (let index = 0; index < message.length; index += 1) {
          //   mess += `<p>Row: ${message[index].row} ${message[index].error}</p>`
          // }
          this.statusMessage = message
        } else {
          this.statusMessage.push(message)
        }
      } else {
        this.statusIcon = 'CheckCircleIcon'
        this.statusCss = 'text-primary'
        this.statusTitle = 'Success!'
        this.statusHeadingCss = 'scl-success-heading'
      }
    },
    showMessage(iconType, heading, message) {
      let mess = ''
      if (Array.isArray(message)) {
        if (iconType === 'warning') {
          mess = 'Import failed with the following warnings...<br><br>'
        } else {
          mess = 'Import failed with the following errors...<br><br>'
        }
        for (let index = 0; index < message.length; index += 1) {
          if (message[index].row === undefined) {
            mess += `${message[index]}<br>`
          } else {
            mess += `<b>Import file row: ${message[index].row}</b><br>${message[index].message}<br>`
          }
        }
      } else {
        mess = message
      }
      let buttonCss = 'btn btn-primary'
      if (iconType === 'warning') {
        buttonCss = 'btn btn-warning'
      } else if (iconType === 'error') {
        buttonCss = 'btn btn-danger'
      }
      swal.fire({
        title: heading,
        html: mess,
        icon: iconType,
        customClass: {
          confirmButton: buttonCss,
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style scoped>
.scl-title-hover:hover,
.active {
  cursor: pointer;
  color: #3CB3A2;
}

.b-form-file.b-custom-control-sm.custom-file-label::after {
  background-color: #3CB3A2;
  border-color: #3CB3A2;
}

.scl-stage-box {
  padding: 1rem;
  flex: 1 1 auto;
  box-sizing: borderbox;
  border: 2px;
  border-color: #3CB3A2;
  border-radius: 9px;
  border-style: solid;
}
.scl-icon-size {
  height: inherit;
  width: inherit;
}
.scl-upload-button {
  background: none;
  border: none;
  color: #3CB3A2;
  width: 100%;
}

.scl-upload-button:hover {
  background: none;
  color: #36a091;
}

.popover {
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.scl-success-heading {
  color: #3CB3A2;
}
.scl-warning-heading {
  color: #FF9F43;
}
.scl-error-heading {
  color: #FF5358;
}
</style>
