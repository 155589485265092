<template>
  <div>
    <b-form
      id="searchForm"
      method="GET"
      @submit.prevent="updateSearchResults"
    >
      <b-row>
        <b-col>
          <b-form-group
            label="Year"
            label-for="year"
          >
            <b-form-select
              id="year"
              v-model="currentSearchCriteria.selectedYear"
              name="year"
              :value="currentSearchCriteria.selectedYear"
              @change="updateSearchResults()"
            >
              <b-form-select-option
                v-for="year in searchYears"
                :key="year.taxyear_id"
                :v-bind="year.taxyear_id"
                :value="year.taxyear_id"
              >
                {{ year.period }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Week"
            label-for="week"
          >
            <b-form-select
              id="week"
              v-model="currentSearchCriteria.selectedWeek"
              name="week"
              @change="updateSearchResults()"
            >
              <b-form-select-option
                v-for="wk in 53"
                :key="wk"
                :v-bind:value="wk"
                :value="wk"
              >
                {{ wk }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <!-- <b-col>
          <b-form-group
            label="Week Ending"
            label-for="week-end"
          >
            <b-form-select
              id="week-end"
              v-model="currentSearchCriteria.selectedWeekEnd"
              name="week-end"
              :value="currentSearchCriteria.selectedWeekEnd"
              @change="updateSearchResults()"
            >
              <b-form-select-option
                value="W/e xx/xx/xx"
              >
                W/e xx/xx/xx
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col> -->
        <b-col>
          <b-form-group
            label="Clients"
            label-for="client"
          >
            <b-form-select
              id="client"
              v-model="currentSearchCriteria.selectedClient"
              name="client"
              :value="currentSearchCriteria.selectedClient"
              @change="updateSearchResults()"
            >
              <b-form-select-option
                value="All clients"
              >
                All clients
              </b-form-select-option>
              <b-form-select-option
                v-for="client in searchClients"
                :key="client.ent_id"
                :v-bind="client.ent_id"
                :value="client.ent_id"
              >
                {{ client.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col />
        <b-col />
        <b-col />
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BFormSelect, BFormSelectOption, BCol, BRow, BFormGroup,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

// import axios from 'axios'
// import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'PaymentsFilterBar',
  components: {
    BForm,
    BCol,
    BRow,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
  },
  computed: {
    ...mapGetters({ currentSearchCriteria: 'app/getCurrentPaymentSearchCriteria' }),
    ...mapGetters({ searchYears: 'app/getSearchYears' }),
    ...mapGetters({ searchClients: 'app/getSearchClients' }),
    ...mapGetters({ params: 'app/getCurrentPaymentSearchCriteriaAsParams' }),
  },
  created() {
    this.$store.dispatch('app/getSearchClients')
    this.$store.dispatch('app/getSearchYears')
    this.$store.commit('app/initCurrentPaymentSearchCriteria')
    this.updateSearchResults()
  },
  methods: {
    updateSearchResults() {
      this.$store.dispatch('app/getClients', this.params)
    },
  },
}
</script>

<style scoped>

</style>
