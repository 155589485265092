<template>
  <div :class="componentCss">
    <div
      class="mb-1"
      :class="{ active: hover }"
      @click="showReview"
      @mouseover="hover = validBatchId"
      @mouseleave="hover = false"
    >
      <h4
        class="float-left scl-title-hover"
      >
        {{ title }}
      </h4>
      <feather-icon
        icon="ChevronRightIcon"
        class="float-right"
        size="24"
      />
      <b-button
        v-if="showNotification === true"
        :id="`${name}-status-button`"
        :variant="statusVariant"
        class="btn-icon rounded-circle float-right mr-2 btn-sm"
      >
        <b-popover
          :target="`${name}-status-button`"
          triggers="click blur"
          placement="topright"
          :variant="statusVariant"
        >
          <template
            #title
          >
            {{ statusTitle }}
          </template>
          {{ statusMessage }}
        </b-popover>
        <!-- eslint-disable -->
        <span v-html="statusIcon" />
        <!-- eslint-enable -->
      </b-button>
    </div>
    <br>
    <router-link
      v-if="validBatchId"
      :to="{ path: `/${page}?batchid=${batchId}` }"
    >
      {{ linkTitle }}
    </router-link>
    <p v-else>
      Nothing to process
    </p>
    <b-progress
      v-model="linkProgress"
      class="mt-1"
      :max="linkProgressMax"
    />
  </div>
</template>

<script>

import { BProgress, BButton, BPopover } from 'bootstrap-vue'

export default {

  name: 'PaymentsStageBox',
  components: {
    BProgress,
    BButton,
    BPopover,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    linkTitle: {
      type: String,
      default: null,
      required: true,
    },
    linkProgressMax: {
      type: Number,
      default: null,
      required: true,
    },
    linkProgress: {
      type: Number,
      default: null,
      required: true,
    },
    batchId: {
      type: Number,
      default: null,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: null,
      required: true,
    },
    enabledStatus: {
      type: Number,
      default: null,
      required: true,
    },
    batchStatus: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      showNotification: false,
      statusIcon: '',
      statusVariant: 'outline-primary',
      statusMessage: '',
      statusTitle: '',
      hover: false,
    }
  },
  computed: {
    componentCss() {
      if (this.batchStatus >= this.enabledStatus) {
        return 'scl-stage-box'
      }
      return 'scl-stage-box-disabled'
    },
    validBatchId() {
      return this.batchId !== -1 && this.batchId !== null && this.batchStatus >= this.enabledStatus
    },
  },
  created() {
    this.setMessage('', false)
    this.showNotification = false
  },
  methods: {
    showReview() {
      if (this.validBatchId !== true) return

      this.$router.push(`/${this.page}?batchid=${this.batchId}`)
    },
    setMessage(message, inError) {
      this.showNotification = true
      if (inError) {
        this.statusIcon = '&#10069;' // decimal for html exclaimation mark
        this.statusTitle = 'Error'
        this.statusMessage = message
        this.statusVariant = 'danger'
      } else {
        this.statusIcon = '&#10004;' // decimal for html tick mark
        this.statusVariant = 'primary'
        this.statusTitle = 'Success!'
      }
    },
  },
}
</script>

<style scoped>
.scl-title-hover:hover,
.active {
  cursor: pointer;
  color: #3CB3A2;
}

.scl-stage-box {
  padding: 1rem;
  flex: 1 1 auto;
  box-sizing: borderbox;
  border: 2px;
  border-color: #3CB3A2;
  border-radius: 9px;
  border-style: solid;
}
.scl-stage-box-disabled {
  padding: 1rem;
  flex: 1 1 auto;
  box-sizing: borderbox;
  border: 2px;
  border-color: gray;
  border-radius: 9px;
  border-style: solid;
  opacity: 0.3;
  pointer-events: none;
}
</style>
