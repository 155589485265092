<template>
  <div>
    <b-row class="mt-2 mb-1">
      <b-col>
        <b-link
          :to="{ path: `/companyeditor?id=${clientData.ent_id}` }"
          class="h2"
        >
          {{ clientData.name }}
        </b-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <PaymentsUploadStageBox
          title="Upload file"
          :link-title="fileName"
          :ppe-title="ppeFileName"
          :ppe-enabled="chargesRequired"
          :batch-id="batchId"
          :client-id="clientData.ent_id"
          :name="`${clientData.ent_id}-upload`"
          :batch-status="batchStatus"
        />
      </b-col>
      <b-col>
        <PaymentsStageBox
          title="Review payments"
          :link-title="reviewProcessedCount"
          :link-progress-max="itemCount"
          :link-progress="reviewedCount"
          :batch-id="batchId"
          page="reviewpayments"
          :name="`${clientData.ent_id}-confirm`"
          :enabled-status="reivewEnabledStatus"
          :batch-status="batchStatus"
        />
      </b-col>
      <b-col>
        <PaymentsStageBox
          title="Submit to Sage"
          :link-title="sageProcessedCount"
          :link-progress-max="itemCount"
          :link-progress="sageSentCount"
          :batch-id="batchId"
          page="submittosage"
          :name="`${clientData.ent_id}-sage`"
          :enabled-status="sageEnabledStatus"
          :batch-status="batchStatus"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BRow, BLink,
} from 'bootstrap-vue'

import PaymentsStageBox from '@/components/payments/ui/PaymentsStageBox.vue'
import PaymentsUploadStageBox from '@/components/payments/ui/PaymentsUploadStageBox.vue'

export default {
  name: 'PaymentsClientBox',
  components: {
    BCol,
    BRow,
    BLink,
    PaymentsUploadStageBox,
    PaymentsStageBox,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reivewEnabledStatus: 2,
      sageEnabledStatus: 4,
    }
  },
  computed: {
    batchId() {
      let value = -1
      if (this.clientData.paybatch !== undefined) {
        if (this.clientData.paybatch.length) {
          value = this.clientData.paybatch[0].id
        }
      }
      return value
    },
    batchStatus() {
      let value = 0
      if (this.clientData.paybatch !== undefined) {
        if (this.clientData.paybatch.length) {
          value = this.clientData.paybatch[0].status
        }
      }
      return value
    },
    fileName() {
      let value = ''
      if (this.clientData.paybatch !== undefined) {
        if (this.clientData.paybatch.length) {
          value = this.clientData.paybatch[0].filename
        }
      }
      return value
    },
    ppeFileName() {
      let value = ''
      if (this.clientData.paybatch !== undefined) {
        if (this.clientData.paybatch.length) {
          value = this.clientData.paybatch[0].chargefilename
        }
      }
      return value
    },
    chargesRequired() {
      if (this.clientData.entityclients.length === 0 || this.clientData.entityclients[0].usecharges === undefined) return false

      return this.clientData.entityclients[0].usecharges
    },
    reviewProcessedCount() {
      let value = 'Nothing to process'
      if (this.clientData.paybatch !== undefined) {
        if (this.clientData.paybatch.length) {
          if (this.clientData.paybatch[0].itemspaid !== undefined) {
            value = `${this.clientData.paybatch[0].itemspaid}/${this.clientData.paybatch[0].itemcount} items`
          } else {
            value = `0/${this.clientData.paybatch[0].itemcount} items`
          }
        }
      }
      return value
    },
    itemCount() {
      if (this.clientData.paybatch === undefined) return 0
      if (this.clientData.paybatch.length === 0) return 0
      if (this.clientData.paybatch[0].itemcount === undefined) return 0

      return this.clientData.paybatch[0].itemcount
    },
    reviewedCount() {
      if (this.clientData.paybatch !== undefined) {
        if (this.clientData.paybatch.length) {
          if (this.clientData.paybatch[0].itemspaid !== undefined) {
            return this.clientData.paybatch[0].itemspaid
          }
          return 0
        }
      }
      return 0
    },
    sageProcessedCount() {
      let value = 'Nothing to process'
      if (this.clientData.paybatch !== undefined) {
        if (this.clientData.paybatch.length) {
          if (this.clientData.paybatch[0].itemssenttosage !== undefined) {
            value = `${this.clientData.paybatch[0].itemssenttosage}/${this.clientData.paybatch[0].itemcount} items` // Need to find this count form somewhere
          } else {
            value = `0/${this.clientData.paybatch[0].itemcount} items` // Need to find this count form somewhere
          }
        }
      }
      return value
    },
    sageSentCount() {
      if (this.clientData.paybatch !== undefined) {
        if (this.clientData.paybatch.length) {
          if (this.clientData.paybatch[0].itemssenttosage !== undefined) {
            return this.clientData.paybatch[0].itemssenttosage
          }
          return 0
        }
      }
      return 0
    },
  },
}
</script>

<style scoped>

</style>
