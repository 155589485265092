<template>
  <div>
    <b-card title="">
      <PaymentsFilterBar />
    </b-card>
    <b-card>
      <PaymentsClientBox
        v-for="client in clients"
        :key="client.ent_id"
        :client-data="client"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import PaymentsFilterBar from '@/components/payments/PaymentsFilterBar.vue'
import PaymentsClientBox from '@/components/payments/ui/PaymentsClientBox.vue'
import { mapGetters } from 'vuex'
import jwt from '@/auth/jwt/sclUseJwt'

export default {
  middleware: ['check-auth', 'auth'],
  components: {
    BCard,
    PaymentsFilterBar,
    PaymentsClientBox,
  },
  computed: {
    ...mapGetters({ clients: 'app/getClients' }),
  },
  created() {
    if (jwt.getToken() === undefined || jwt.getToken() === '') {
      this.$router.push('/')
    }
  },
}
</script>

<style>

</style>
